body {
}
form label{
    font-weight:700;
}
.sync_padd_10{
    padding:10px;
}
.a_btn{
    cursor:pointer;
}
#admin_pending_btn .btn-floating.btn-sm i {
    font-size: 2em;
    position: absolute;
    top: 0px;
    right: 0px;
}
#adminMemberReg .dobWrapper div {
    width: 100%;
}
/*=====AVATAR=====*/
/*.circular {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 5px solid #d7d7d7;
}*/

    /*.circular img {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}*/
.circular img {
    vertical-align: middle;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    border: 5px solid rgb(233, 230, 230);
}
.memberFieldBtn .btn{
    width:80%;
}
.committeeRelation .btn{
    width:46%;
}
.btnCommitteeAccess{
    width:40%;
}
.btnCommitteeAccess{
    width:100%;
}
.btnCommitteeAccessDelete {
    padding: .84rem 1rem;
}
#committeeRelationDashboard .modal-title,
#donation .modal-title {
    color: #fff;
    font-family: Georgiya,Arial,serif
}
#greetingCardList .cardTitleWrapper {
    background: rgba(0,0,0,0.5);
    color: #fff;
    font-weight: 400;
    text-align: center;
    padding: 10px 0;
    position: absolute;
    display: block;
    overflow: hidden;
    width: 100%;
    bottom: 0;
}
/*Report*/
/* #membersSummaryReport {

}
#membersSummaryReport {

} */

#membersSummaryReport th,
#membersSummaryReport td {
    font-size:16px;
}

/*====================
Approved List
====================*/
.searchContainer img {
    width: 40px;
    position: relative;
    left: 10px;
}
/*=================
    RESPONSIVE
 ==================*/
@media (min-width: 320px) {
    .side-nav .logo-wrapper img {
        padding: 0;
        margin: 0 auto;
    }
}

@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
    