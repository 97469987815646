/* Stepper Form */

/* Stepper v.2 (Form) */
.steps-form {
  display: table;
  width: 100%;
  position: relative;
}

.steps-form .steps-row {
  display: table-row;
}

.steps-form .steps-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.steps-form .steps-row .steps-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.steps-form .steps-row .steps-step p {
  margin-top: 0.5rem;
}

.steps-form .steps-row .steps-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.steps-form .steps-row .steps-step .btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  margin-top: 0;
}

/* Stepper v.3 (Icons) */
.steps-form-2 {
  display: table;
  width: 100%;
  position: relative;
}

.steps-form-2 .steps-row-2 {
  display: table-row;
}

.steps-form-2 .steps-row-2:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 99%;
  height: 2px;
  background-color: #7283a7;
}

.steps-form-2 .steps-row-2 .steps-step-2 {
  display: table-cell;
  text-align: center;
  position: relative;
}

.steps-form-2 .steps-row-2 .steps-step-2 p {
  margin-top: 0.5rem;
}

.steps-form-2 .steps-row-2 .steps-step-2 button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 {
  width: 70px;
  height: 70px;
  border: 2px solid #59698d;
  background-color: white !important;
  color: #59698d !important;
  border-radius: 50%;
  padding: 20px 20px 20px 20px;
  margin-top: -22px;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2:hover {
  border: 2px solid #4285f4;
  color: #4285f4 !important;
  background-color: white !important;
}

.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .fa,
.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .fas,
.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .far,
.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .fal,
.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .fad,
.steps-form-2 .steps-row-2 .steps-step-2 .btn-circle-2 .fab {
  font-size: 1.7rem;
}

.steps-row-2:first-child .btn {
  margin-left: 0;
}

.steps-row-2:last-child .btn {
  margin-right: 0;
}

/* Stepper v.4 (Icon-vertical) */

.steps-form-3 {
  width: 2px;
  height: 470px;
  position: relative;
}

.steps-form-3 .steps-row-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.steps-form-3 .steps-row-3:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: '';
  width: 2px;
  height: 100%;
  background-color: #7283a7;
}

.steps-form-3 .steps-row-3 .steps-step-3 {
  height: 150px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  position: relative;
}

.steps-form-3 .steps-row-3 .steps-step-3.no-height {
  height: 50px;
}

.steps-form-3 .steps-row-3 .steps-step-3 p {
  margin-top: 0.5rem;
}

.steps-form-3 .steps-row-3 .steps-step-3 button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.steps-form-3 .steps-row-3 .steps-step-3 .btn-circle-3 {
  width: 60px;
  height: 60px;
  border: 2px solid #59698d;
  background-color: white !important;
  color: #59698d !important;
  border-radius: 50%;
  padding: 15px 15px 15px 15px;
  margin-top: -22px;
}

.steps-form-3 .steps-row-3 .steps-step-3 .btn-circle-3:hover {
  border: 2px solid #4285f4;
  color: #4285f4 !important;
  background-color: white !important;
}

.steps-form-3 .steps-row-3 .steps-step-3 .btn-circle-3 .fa,
.steps-form-3 .steps-row-3 .steps-step-3 .btn-circle-3 .fas,
.steps-form-3 .steps-row-3 .steps-step-3 .btn-circle-3 .far,
.steps-form-3 .steps-row-3 .steps-step-3 .btn-circle-3 .fal,
.steps-form-3 .steps-row-3 .steps-step-3 .btn-circle-3 .fad,
.steps-form-3 .steps-row-3 .steps-step-3 .btn-circle-3 .fab {
  font-size: 1.7rem;
}
