.popover {
  width: auto;
  background-color: white;
  color: #97999b;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  font-size: 0.83em;
  font-weight: normal;
  border: 1px rgb(0, 0, 0) solid;
  /* z-index: 200000; */
  z-index: 10;
  /* max-width: initial; */
  max-width: 274px;
  text-align: start;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
}

.show.popover {
  z-index: 999;
  opacity: 1;
  visibility: visible;
}

.popover-body {
  color: #6c6e71;
}

.popover .popover_arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 6px;
  color: transparent;
}

.popover[x-placement^="top"] {
  margin-bottom: 15px;
}

.popover[x-placement^="top"] .popover_arrow {
  border-width: 8px 8px 0 8px;
  border-color: #d6d6d6 transparent transparent transparent;
  bottom: -8px;
  margin-bottom: 0;
}

.popover[x-placement^="top"] .popover_arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -8px;
  bottom: 1.5px;
  border: solid;
  border-width: 8px 8px 0 8px;
  border-color: white transparent transparent transparent;
}

.popover[x-placement^="bottom"] {
  margin-top: 15px;
}

.popover[x-placement^="bottom"] .popover_arrow {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #d6d6d6 transparent;
  top: -8px;
  margin-top: 0;
}

.popover[x-placement^="bottom"] .popover_arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -8px;
  top: 1.45px;
  border: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent white transparent;
}

.popover[x-placement^="right"] {
  margin-left: 15px;
}

.popover[x-placement^="right"] .popover_arrow {
  border-width: 8px 8px 8px 0;
  border-color: transparent #d6d6d6 transparent transparent;
  left: -8px;
  margin-left: 0;
}

.popover[x-placement^="right"] .popover_arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -8px;
  left: 1.45px;
  border: solid;
  border-width: 8px 8px 8px 0;
  border-color: transparent white transparent transparent;
}

.popover[x-placement^="left"] {
  margin-right: 15px;
}

.popover[x-placement^="left"] .popover_arrow {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #d6d6d6;
  right: -8px;
  margin-right: 0;
}

.popover[x-placement^="left"] .popover_arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -8px;
  right: 1.45px;
  border: solid;
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent white;
}

.tooltip {
  width: auto;
  background-color: black;
  color: white;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  /* font-size: 0.83em; */
  font-weight: normal;
  border: 1px rgb(0, 0, 0) solid;
  /* z-index: 200000; */
  z-index: 15;
  /* max-width: initial; */
  max-width: 274px;
  text-align: start;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
}

.tooltip-inner {
  display: block;
}

.show.tooltip {
  z-index: 999;

  opacity: 1;
  visibility: visible;
}

.tooltip .popover_arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 6px;
  color: transparent;
}

.tooltip[x-placement^="top"],
.show[x-placement^="top"]:not(.tooltip) {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .popover_arrow {
  border-width: 6px 6px 0 6px;
  border-color: #131313 transparent transparent transparent;
  bottom: -6px;
  margin-bottom: 0;
}

.tooltip[x-placement^="top"] .popover_arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -6px;
  bottom: 1.5px;
  border: solid;
  border-width: 6px 6px 0 6px;
  border-color: black transparent transparent transparent;
}

.tooltip[x-placement^="bottom"],
.show[x-placement^="bottom"]:not(.tooltip) {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .popover_arrow {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #131313 transparent;
  top: -6px;
  margin-top: 0;
}

.tooltip[x-placement^="bottom"] .popover_arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -6px;
  top: 1.45px;
  border: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent black transparent;
}

.tooltip[x-placement^="right"],
.show[x-placement^="right"]:not(.tooltip) {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .popover_arrow {
  border-width: 6px 6px 6px 0;
  border-color: transparent #131313 transparent transparent;
  left: -6px;
  margin-left: 0;
}

.tooltip[x-placement^="right"] .popover_arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 1.45px;
  border: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent black transparent transparent;
}

.tooltip[x-placement^="left"],
.show[x-placement^="left"]:not(.tooltip) {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .popover_arrow {
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #131313;
  right: -6px;
  margin-right: 0;
}

.tooltip[x-placement^="left"] .popover_arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -6px;
  right: 1.45px;
  border: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent black;
}
