#searchContent {
    display: block;
    overflow: hidden;
    margin: 0 auto;
    width: 30vw;
}
#searchMediaButton,
#searchMediaView {
    display: block;
    overflow: hidden;
    text-align: center;
}