/*==================
    START GLOBAL STYLE
====================*/
    html, body {
        height: 100%;
    }
    #clientApp p {
        font-size: 16px;
        line-height:35px
    }
#clientApp .clientAppTopNav .navbar-toggler {
    background: #222;
}
#clientApp .client_dob div {
    width: 100%;
}
/*==================
    END GLOBAL STYLE
====================*/

#clientApp .nav-link span {
    text-transform: capitalize;
    margin-left: 2px;
    font-family: arial,serif,sans-serif;
    font-weight: 400;
    font-size: 18px;
}
/*Dropdown Menu*/
#clientApp .dropdown-menu {
    background:rgba(0,0,0,1);
}
#clientApp .navbar .dropdown-menu a:not(.active) {
    color:#fff;
    font-size:16px;
}
#clientApp .dropdown .dropdown-menu .dropdown-item:hover {
    background: #64686fd4;
}
#clientApp .dropdown-toggle::after {
    position: absolute;
    right: -3%;
    top: 52%;
}
#clientApp .profileWrapper {
    overflow: hidden;
    display: block;
    width: auto;
    height: auto;
    padding-top: 35px;
    padding-bottom: 20px;
}
#clientApp .profileWrapper img {
}
#clientApp .aboutUs {
    width: 75%;
    float: right;
    padding-top:10px
}

/*================
     HOME PAGE
==================*/
#clientApp .sloganWrapper {
    /*background: rgba(255,0,0,0.5) url('../images/speechBg.jpg');*/
    background: linear-gradient(90deg, rgba(251,217,252,1) 0%, rgba(211,232,255,1) 35%, rgba(0,212,255,1) 100%); 
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding: 20px;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
}
#clientApp .sloganWrapper ul {
    list-style:none;
    margin:0;
    padding:0;
}
#clientApp .sloganWrapper ul li i {
    position: relative;
    top: 8px;
}
#clientApp .sloganWrapper ul li {
    color: #fff;
    display: block;
    overflow: hidden;
    font-weight:400;
}

#clientApp .sloganWrapper h3 {
    width: 300px;
    background: #fff;
    display: block;
    font-size: 22px;
    padding: 15px;
    color: #52b7c1;
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 35px;
    border: 4px solid #d8872a;
}
#clientApp .sloganWrapper a {
    width:200px;
}
#clientApp .sloganWrapper p {
    width: 100%;
    float: right;
    background: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    background: #fff;
    color: #000;
}
#clientApp .readMore {
    text-align: center;
    display: block;
    margin: 0 auto;
}
#clientApp .committeeWrapper {
    position: relative;
    padding: 20px 0;
}
#clientApp .committeeWrapper img {
    display: block;
    text-align: center;
    margin: 0 auto;
}
#clientApp .committeeWrapper h3 {
    font-size: 20px;
    display: block;
    overflow: hidden;
    text-align: center;
    padding: 15px 0;
    border-bottom: 2px solid #d7d7d7;
    box-shadow: 1px 1px 4px;
    color: #0c8643;
    font-weight: 700;
}
#clientApp .cardWrapper strong {
    display: block;
    padding: 5px 0;
    color: green;
    font-weight: 700;
}
#clientApp .cardWrapper span {
    display: block;
    font-size:18px;
    color:#222;
    padding:5px 0;
}
#clientApp .cardWrapper u {
    display: block;
    font-size: 14px;
    color: #947b7b;
}
#clientApp .cardWrapper p {
    font-size: 16px;
    line-height: 25px;
}

.committee_title {
    background: #28a745;
    padding: 10PX;
    border-top: 5px solid #dc3545;
    border-bottom: 5px solid #dc3545;
    text-align: center;
    color: #fff;
}

/*.client_bg {
    background-color: white;    
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.client_bg {
    background-image: url('../images/under_construction.png');
    height: 800px;
}
.client_reg_banner {
    background-image: url('../images/np_banner_01.jpg');
    height: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;
}*/

.client_reg_banner img { 
    width:100%;
}
.client_default_avatar {
    height:150px;
}
.dtpDivFullWidth div {
    width: 100%;
}
#client_reg .client_title h1 {
    background: rgba(0,0,0,0.60);
    padding: 15px 30px 15px 30px;
}
#client_reg .reg_contact div:first-child{
    margin-top:0;
    margin-bottom:0;
    width:90%;
}

#client_reg .reglabelClassError {
    color: red;
}
#client_reg .reglabelClassSuccess {
    color: red;
}
#client_reg .regLoader{
    margin:0 auto;
}
/*===================
    LOGIN
*====================*/
.client_login .modal-header {
    border: none;
}
.client_login .modal-content {
    background:none !important;
}
.client_login .close {
    position: absolute;
    right: 6%;
    top: 12%;
    z-index: 5000;
}
.client_login .card-body button{
    width: 100%;
}

#clientApp .customFooter {
    background:#444;
}

#clientApp .customFooter li a{
    font-size:16px;
    line-height:35px;
}

/*==================
    PAGE
 =================*/
#clientApp .pageContainer {
    margin-top:2%
}
    #clientApp .pageContainer th,
    #clientApp .pageContainer td{
        font-size:16px;
    }

/*Page Template*/
#clientApp .pageWrapper img {
    border: 5px inset #d2c22c;
}
#clientApp .pageWrapper strong {
    display: block;
    font-size: 22px;
    text-align: center;
    padding: 10px 0 5px 0;
}
#clientApp .pageWrapper span {
    font-size: 18px;
    text-align: center;
    display: block;
}
/*
    CONTACT PAGE
*/

#clientApp .contactWrapper {
    background: rgba(255,0,0,0.5) url('../images/contact.png');
    /*background: linear-gradient(90deg, rgba(251,217,252,1) 0%, rgba(211,232,255,1) 35%, rgba(0,212,255,1) 100%);*/
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    padding: 20px;
    height:500px;
}
#clientApp #contact {
    background: rgba(255,255,255,0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 2px 3px #a59c9c;
    position: relative;
    top: 100px;
}
#clientApp #contact ul {
    list-style:none;
}
#clientApp #contact ul li {
    padding: 5px 0;
    color: #000;
    font-weight: 700;
}
/*=======================
    ALL LIST PAGE
========================*/
.listTitle {
    width: 70%;
    float: left;
}
.searchContainer {
    width: 30%;
    float: right;
}
/*=================
    RESPONSIVE
 ==================*/
    @media (min-width: 320px) {
        #clientApp .clientAppTopNav {
            background: #1e283ad4 !important;
        }
    #clientApp .mr-auto {
        background: rgba(0,0,0,0.8) !important;
    }
    #clientApp .dropdown-toggle .d-none {
        display: block !important;
    }
    #clientApp .dropdown-toggle::after {
        top: 62%;
        right: -4%;
    }
    #clientApp .profileWrapper img {
        display: block;
        margin: 0 auto;
    }

    #clientApp .aboutUs {
        width: 100%;
    }
    #clientApp .aboutUs p{
        text-align:center;
    }

    #clientApp #about_home_icon {
        display: block;
        text-align: center;
    }
    #clientApp .navbar-brand img {
        display: none;
    }
        #clientApp .customCard {
            margin: 20px 0;
        }

    #clientApp .customFooter ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        overflow: hidden;
        padding: 15px 0;
        padding-left:20px;
    }

    #clientApp .customFooter ul li {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        overflow: hidden;
    }

    #clientApp .npSocial > li {
        display: inline-block !important;
    }
    /*Registration*/
    #clientApp .white-text {
        font-size:20px;
    }
    /*Page template*/
        #clientApp .pageWrapper {
            margin-top: 10px;
            margin-bottom:20px;
        }
            #clientApp .pageWrapper h3 {
                margin-top:50px;
            }
            #clientApp .pageWrapper .textCenter {
                text-align:center;
            }
}

@media (min-width: 576px) {        

    /*#clientApp .sloganWrapper p {
        width: 92%;
    }*/

    #clientApp .customCard {
        padding-bottom: 30px;
    }
    #clientApp .customFooter ul li span {
        margin-left: 5px;
    }
    #clientApp .customFooter ul {
        padding-left:0;
    }

    /*=======================
    EVERY PAGE
    ========================*/
    .listTitle {
        width: 100%;
        text-align: center;
        font-size: 22px;
    }
    .searchContainer {
        width: 100%;
    }

}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    #clientApp .clientAppTopNav {
        /*background-color: rgba(0,0,0,.60) !important;*/
        background-color: #444 !important;
        border-bottom:3px solid red;
    }
    #clientApp .mr-auto {
        background: none !important;
    }
    #clientApp .navbar-brand img {
        display: inline-block;
    }
    #clientApp .nav-link i {
        display: block;
        text-align:center;
    }
    #clientApp .nav-link span {
        font-size: 16px;
        text-align: center;
        display: block;
        margin-top: 5px;
    }
    #clientApp .committeeWrapper img {
        width: 100%;
    }
    /*Registration*/
    #clientApp .white-text {
        font-size: 30px;
    }
    /*Page Template*/
    #clientApp .pageWrapper {
        margin-top: 50px;
    }
    #clientApp .pageWrapper h3 {
        margin-top: 0px;
    }
    #clientApp .pageWrapper .textCenter {
        text-align: left;
    }
    #clientApp .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    /*=======================
    EVERY PAGE
    ========================*/
    .listTitle {
        width: 70%;
        text-align: left;
    }
    .searchContainer {
        width: 30%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    #clientApp .nav-link span {
        font-size: 16px;
    }
    #clientApp .dropdown-toggle::after {
        top: 62%;
        right: -4%;
    }
    #clientApp .profileWrapper img {
        display: inline-block;
    }
    #clientApp .aboutUs {
        width: 70%;
        position: relative;
        top: 50px;
    }
    #clientApp .aboutUs p {
        text-align: left;
    }
    #clientApp #about_home_icon {
        text-align: left;
    }
    #clientApp .committeeWrapper img {
        width:90%;
    }
    /*#clientApp .sloganWrapper p {
        width:96%;
    }*/    
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    #clientApp .nav-link i {
        display: inline-block;
        text-align: left;
    }
    #clientApp .nav-link span {
        font-size: 18px;
        display:inline-block;
    }
    #clientApp .aboutUs {
        width: 75%;        
    }
    #clientApp .committeeWrapper img {
        width: 100%;
    }
}
