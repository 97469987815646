/*========DRAG & DROP FILES============*/
.dropzoneContainer {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.dropzone {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: rgb(238, 238, 238);
    border-style: dashed;
    background-color: rgb(250, 250, 250);
    color: rgb(189, 189, 189);
    outline: none;
    transition: border 0.24s ease-in-out 0
}

/*======== IMAGE PREVIEW ===========*/

.dropzoneImagePreview .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16;
}

.dropzoneImagePreview .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}

.dropzoneImagePreview .thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden
}

.dropzoneImagePreview .img {
    display: block;
    width: auto;
    height: 100%
}

