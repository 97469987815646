.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

.dropdown-menu-right {
  left: 0 !important;
  right: auto !important;
}
